@import url('./xy-theme.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
 
/* ... */
 
 
#root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  width: 100vw;
  height: 100vh;
  color-scheme: light dark;
 
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
a {
  font-weight: 500;
  /* color: #646cff; */
  text-decoration: inherit;
}
 
/* a:hover {
  color: #535bf2;
} */
 
body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}
 
h1 {
  font-size: 3.2em;
  line-height: 1.1;
}
 

 
@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

 
  /* button {
    background-color: #f9f9f9;
  } */
}
 
.node-shape{
  border: 4px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;
 
}
 
.node-shape.circle{
  border-radius: 50%;
}
 
.node-shape.line{
  border-top: 4px solid black;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
 
.node-shape.diamond {
  transform: rotate(-45deg);
}
.node-shape.diamond:hover {
  transform: rotate(45deg) !important;
}
 
 
 
 
.node-shape.affected{
  background-color: black;
}
 
/* Adopted in */
.node-shape.adopted-in {
  position: relative; /* Ensure the node is positioned relatively to its container */
}
 
.node-shape.adopted-in::before {
  content: '[';
  position: absolute;
  left: -40px; /* Adjust spacing as needed */
  top: 40%; /* Vertically center align */
  transform: translateY(-50%);
  font-size: 90px;
  font-weight: 300;
}
 
.node-shape.adopted-in::after {
  content: ']';
  position: absolute;
  right: -40px; /* Adjust spacing as needed */
  top: 40%; /* Vertically center align */
  transform: translateY(-50%);
  font-size: 90px;
  font-weight: 300;
}
 
/* Adopted out */
.node-shape.adopted-out {
  position: relative; /* Ensure the node is positioned relatively to its container */
}
 
.node-shape.adopted-out::before {
  content: ']';
  position: absolute;
  left: -40px; /* Adjust spacing as needed */
  top: 40%; /* Vertically center align */
  transform: translateY(-50%);
  font-size: 90px;
  font-weight: 300;
}
 
.node-shape.adopted-out::after {
  content: '[';
  position: absolute;
  right: -40px; /* Adjust spacing as needed */
  top: 40%; /* Vertically center align */
  transform: translateY(-50%);
  font-size: 90px;
  font-weight: 300;
}
 
 
.proband-arrow {
  position: absolute;
  left: -2.275rem;
  /* Adjust as needed to position the arrow */
  font-size: 3rem;
  /* Adjust size a needed */
  transform: translateY(100%);
  /* Center vertically */
}
 
.node-shape.deceased::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  transform: scale(1.55) rotate(-45deg);
  top: 50%;
  left: 0%;
}
 
.react-flow__node-ghost,
.react-flow__node-ghost.selected.selectable,
.react-flow__node-ghost.selectable:focus {
  border: 0;
  box-shadow: none;
  background-color: transparent;
  height: 0px;
  width: 0px;
}
 
/* Text Input Node */
 
.react-flow__node-textinput {
  width: 150px;
  font-family: monospace;
  text-align: left;
}
 
.text-input-node__input {
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0px;
  border-radius: 3px;
  border: var(--xy-node-border-default);
}
 
.text-input-node__input::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
 
.text-input-node__input {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}
 
/* Annotation Node */
 
.react-flow__node-annotation {
  font-size: 16px;
  width: 200px;
  color: #683bfa;
  position: absolute;
  box-shadow: none;
  font-family: monospace;
  text-align: left;
  background-color: transparent;
  border: none;
}
 
.react-flow__node-annotation .annotation-content {
  padding: 10px;
  display: flex;
}
 
.react-flow__node-annotation .annotation-level {
  margin-right: 4px;
}
 
.react-flow__node-annotation .annotation-arrow {
  position: absolute;
  font-size: 24px;
}
 
 
 
 
/* Toolbar Node */
 
.react-flow__node-toolbar {
  background-color: #222222;
  border-radius: 1rem;
  overflow: auto;
  margin-top: 2px;
}
 
.react-flow__node-toolbar button:hover {
  background: #4d4d4d;
}
 
/* Resizer Node */
 
.resizer-node__handles {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-evenly;
  left: 0;
}
 
.resizer-node__handle {
  position: relative;
  left: 0;
  transform: none;
}
 
/* Button Edge */
 
.button-edge__label {
  position: absolute;
  pointer-events: all;
  transform-origin: center;
}
 
.button-edge__button {
  width: 30px;
  height: 30px;
  border: 5px solid #f7f9fb;
  color: var(--xy-edge-node-color-default);
  background-color: #f3f3f4;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  padding-top: 0px;
}
 
.button-edge__button:hover {
  background-color: var(--xy-theme-hover);
  color: #ffffff;
}
 
.react-flow__edge-textbg {
  fill: #f7f9fb;
  
}
 
 
/* Custom Handles */
 
.react-flow__handle.custom-handle {
  background-color: #0F172A;
  border-radius: 1px;
  width: 0.5rem;
  height: 0.5rem;
  border: none;
  min-width: 2px;
  min-height: 2px;
  opacity: 10%;
}
 
.react-flow__handle.custom-handle-100 {
  background-color: #0F172A;
  border-radius: 1px;
  width: 0.5rem;
  height: 0.5rem;
  border: none;
  min-width: 2px;
  min-height: 2px;
  opacity: 100%;
}
 
 
.react-flow__handle.custom-handle.connectionindicator:focus,
.react-flow__handle.custom-handle.connectingfrom,
.react-flow__handle.custom-handle.connectingto {
  background-color: var(--xy-theme-edge-hover);
}
 
/* Minimap */
 
.react-flow__minimap .group {
  fill-opacity: 0.4;
}
 
.react-flow__minimap .resizer,
.react-flow__minimap .tools,
.react-flow__minimap .circle,
.react-flow__minimap .textinput {
  fill: rgb(208, 192, 247);
}
 
.react-flow__minimap .circle {
  rx: 100%;
  ry: 100%;
}
 
.react-flow__minimap .annotation {
  display: none;
}
 
.ghost-handle{
  opacity: 0;
}
 
.ghost-edge{
  opacity: 0;
}
 
.divorced-label{
  position: absolute;
}
 
.divorced-label::before,
.divorced-label::after {
  content: '';
  position: absolute;
  width: 50px;
  /* Length of the line */
  height: 3px;
  /* Thickness of the line */
  background-color: black;
  /* Line color */
  transform-origin: left;
  transform: rotate(-45deg);
  /* Diagonal line angle */
}
 
.divorced-label::before {
  /* First line position */
  top: 15px;
  left: -22px;
}
 
.divorced-label::after {
  /* Second line position (4px lower) */
  top: 15px;
  left: -10px;
}
 
.node-label {
  position: absolute;
  width: 15rem;
  height: 4rem;
  bottom: -3rem;
  font-size: 1.5rem;
  text-align: center;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%) translateY(30%); /* Center both directions */
  white-space: nowrap; /* Prevents text from wrapping */
}
 
.additional-info{
  position: absolute;
  left: 1rem;
  font-size: 1.5rem;
  /* Adjust size as needed */
  transform: translateY(60%);
  /* Center vertically */
}
 
.react-flow__panel.react-flow__controls{
  left:auto !important;
  bottom: auto !important;
  overflow-y:hidden !important;

}
 
.react-flow__controls{
  flex-direction: row;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 0.5rem;
  background: white;
}
 
.react-flow__controls-button{
  height: 100%;
  width: 1rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  padding-top: 0.8rem;
  padding-bottom:0.8rem;
 
  
}
 
.react-flow__controls-button:hover{
  background: #f4f4f4;
  min-height: 2.9rem;
  height: 2rem;
}
 
.react-flow__controls-button svg{
  max-width: 40px;
  max-height: 40px;
  height: 15px;
  width: 15px;
}
 
.pedigree-layout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.pedigree-library-layout{
  height: 100% !important;
}
.react-flow__controls-button{
  height: 4rem;
  width: 4rem;
  padding-top: 0.8rem;
  padding-bottom:0.8rem;
}
 
 
 
.pedigree-basic-info {
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  
  gap: 1.5rem !important;
  width: 100% !important;
  padding: 1rem 0 !important;
}
.left-container{
  margin-left: 1.5rem !important;
}
.pedigree-tree-container {
  flex-grow: 1 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
 
.react-flow__panel.react-flow__controls{
  max-width: 80%;
  overflow-x: auto;  
}
 
.click-to-edit-input{
  border-right: 0;
  border-top: 0;
  border-left: 0;
}
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    /* @apply bg-background text-foreground; */
  }
}
 
.node-shape.carrier {
  position: relative; /* Ensure the node is a positioned element */
}
 
.node-shape.carrier::after {
  content: ''; /* Create an empty pseudo-element */
  position: absolute; /* Position it inside the node */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  width: 8px; /* Size of the dot */
  height: 8px; /* Size of the dot */
  background-color: black; /* Color of the dot */
  border-radius: 50%; /* Make the dot circular */
  transform: translate(-50%, -50%); /* Adjust for centering */
}
.mztwin-edge {
  stroke: #000; /* Example, ensure it has a visible color */
  stroke-width: 2px; /* Adjust stroke width */
}
 
 
.node-shape.highlighted {
  transform: scale(0.9);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
   /* Specific styles for diseases */
  
  /* Bowel Polyps */
.disease-diagonal-strokes {
    background: repeating-linear-gradient(
      45deg,
      white,
      white 5px,
      black 5px,
      black 8px);
  border-radius: 0;
}
 
.disease-horizontal-strokes{
  background: repeating-linear-gradient(
      0deg,
      white,
      white 5px,
      black 5px,
      black 8px);
  border-radius: 0;
}
 
.disease-horizontal-strokes.female{
  border-radius: 50%;
}
 
.disease-vertical-strokes{
  background: repeating-linear-gradient(
      90deg,
      white,
      white 5px,
      black 5px,
      black 8px);
  border-radius: 0;
}
 
.disease-vertical-strokes.female{
  border-radius: 50%;
}
 
.disease-diagonal-checkered{
  background:
  repeating-linear-gradient(
    45deg,
    white,
    white 5px,
    black 5px,
    black 8px
  ),
  repeating-linear-gradient(
    135deg,
    white,
    white 5px,
    black 5px,
    black 8px
  );
  background-blend-mode: multiply;
  border-radius: 0;
}
 
.disease-diagonal-checkered.female{
  border-radius: 50%;
}
 
.disease-diagonal-strokes.female {
  border-radius: 50%;
  /* Circle for female */
}
 
.disease-checkered{
  background:
  repeating-linear-gradient(
    90deg,
    white,
    white 5px,
    black 5px,
    black 8px
  ),
  repeating-linear-gradient(
    0deg,
    white,
    white 5px,
    black 5px,
    black 8px
  );
  background-blend-mode: multiply;
  border-radius: 0;
}
 
.disease-checkered.female{
  border-radius: 50%;
}
 
/* Breast Cancer */
.disease-right-half-filled {
 
  /* Ensure the width and height are equal for a perfect circle */
  background: linear-gradient(to left, black 50%, white 50%);
}
 
.disease-right-half-filled.female{
  border-radius: 50%;
}
 
.disease-left-half-filled {
 
  /* Ensure the width and height are equal for a perfect circle */
  background: linear-gradient(to right, black 50%, white 50%);
}
 
.disease-left-half-filled.female{
  border-radius: 50%;
}
 
/* Bilateral Breast Cancer */
.disease-filled {
  background-color: black;
  /* Use notes to distinguish if necessary */
}
 
.disease-filled.female{
  border-radius: 50%;
}
 
/* Ovarian Cancer */
.disease-bottom-half-filled {
 
  /* Ensure the width and height are equal for a perfect circle */
  background: linear-gradient(to top, black 50%, white 50%);
}
.disease-bottom-half-filled.female {
  border-radius: 50%;
}
 
/* Bowel Cancer */
.disease-top-half-filled {
 
  background:
    linear-gradient(to bottom,
      black 50%,
      white 50%);
  /* Top half black, bottom half white */
  border-radius: 0;
  /* Default square shape */
}
 
.disease-top-half-filled.female {
  border-radius: 50%;
  /* Circle for female */
}
 
 
/* Prostate Cancer */
.disease-top-right-quarter-filled {
 
  /* Ensure the width and height are equal for a perfect square */
  background-color: white;
  /* Ensure the background is white by default */
  position: relative;
  /* Required for positioning the pseudo-element */
  border-radius: 0;
  /* Square shape */
  overflow: hidden;
}
 
.disease-top-right-quarter-filled.female{
  border-radius: 50%;
  overflow: hidden;
}
 
.disease-top-right-quarter-filled::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  /* Position the shaded part in the top-right corner */
  width: 50%;
  /* Size of the shaded corner */
  height: 50%;
  background-color: black;
  /* Shaded part in black */
}
 
 
/* Breast/Ovarian Cancer  left */
.disease- {
  background: linear-gradient(to right, black 50%, white 50%);
}
 
/* Other Cancer */
.disease-top-left-quarter-filled {
 
  background: white;
  border-radius: 0;
  /* Square by default */
  position: relative;
  overflow: hidden;
}
 
.disease-top-left-quarter-filled.female{
  border-radius: 50%;
  overflow: hidden;
}
 
.disease-top-left-quarter-filled::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  /* Adjust the size of the shaded corner */
  height: 50%;
  background-color: black;
 
}
 
.disease-bottom-left-quarter-filled::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45%;
  /* Adjust the size of the shaded corner */
  height: 50%;
  background-color: black;
 
}
 
.female.disease-top-left-quarter-filled::after {
  border-top-left-radius: 50%;
}
 
.disease-cross-filled {
  position: relative;
  background: white; /* Background color for the other area */
  border-radius: 0;
  overflow: hidden;
}
 
.disease-cross-filled::before,
.disease-cross-filled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background: black; /* Black color for the filled sections */
  border-radius: 0;
}
 
.disease-cross-filled::after {
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  background: black; /* Bottom-right filled with black */
}
 
.disease-cross-filled.female{
  border-radius: 50%;
}
 
.disease-top-half-strokes {
  position: relative;
  background: white; /* Keeps the bottom half white */
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  overflow: hidden; /* Prevents overflow of pseudo-elements */
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.disease-top-half-strokes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Covers the top half */
  background: repeating-linear-gradient(
    45deg,
    white,
    white 5px,
    black 5px,
    black 8px
  );
}
 
.disease-top-half-strokes::after {
  content: "";
  position: absolute;
  top: 50%; /* Centers the line */
  left: 0;
  width: 100%;
  height: 2px; /* Thickness of the horizontal line */
  background: black;
}
 
 
.disease-top-half-strokes.female{
  border-radius: 50%
}
 
.menu-container {
  position: absolute;
  top: 0;
  left: -150px; /* Positioned to the left of the toolbar */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  padding: 10px;
}
 
.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
 
.menu-list li {
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}
 
.menu-list li:hover {
  background-color: #f0f0f0;
}
 
.floating-toolbar {
  display: block;
/* padding: 0.5rem;  */
 
  
  
}
 
.toolbar-button {
  background: #007bff;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
 
@media (max-width: 920px) {
  .toolbar-button{
    font-size: 12px;
    padding: 6px 8px;
  }
}
 
.toolbar-button:hover {
  background: #111111;
}
 
.add-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  padding: 4px;
}

.shift-left{
  margin-left: -37px;
  margin-top: 10px;
}
 
.toolbar-option {
  background: none;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  text-align: left;
}
 
.toolbar-option:hover {
  background: #f1f1f1;
}
.Toolbar{
  margin-right: 0.5rem !important;
  margin-top: 1rem !important;
  font-size: 28px !important;
  cursor: 'pointer' !important;
}
.toolbar-text {
  position: absolute !important;
  left: 6% !important;
  transform: translateX(-50%) !important;
  padding: 0.25rem 0.5rem !important; /* Equivalent to px-2 py-1 */
  background-color: lightgray !important;
  color: black !important;
  font-size: 0.875rem !important; /* Equivalent to text-sm */
  border-radius: 0.25rem !important; /* Equivalent to rounded */
  opacity: 1 !important; /* Equivalent to opacity-100 */
  transition: opacity 0.2s ease-in-out !important;
  z-index:1 !important; /* Equivalent to transition-opacity */
}
.cut {
  position: relative;
  border: 2px dashed black; /* Creates a dashed line */
  width: 100%;
 
}
 
@keyframes dashAnimation {
  0% {
    stroke-dashoffset: 10;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.animated-dash {
  animation: dashAnimation 0.1s linear infinite;
}
 
#library-container:fullscreen {
  background-color: white; /* Example: Change background color in fullscreen */
  width: 100vw; /* Ensure it takes the full viewport width */
  height: 100vh; /* Ensure it takes the full viewport height */
}